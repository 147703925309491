<template>
  <div>
    <template v-for="(subject_name, subject_name_index) in d_workSubjectList.subject_order">
      <template v-if="p_showDetails.type === 'all' || (p_showDetails.type === 'selected' && p_showDetails.subject_name === subject_name)">
        <h3> {{ d_workSubjectList.subjects[subject_name].label }} </h3>
        <h6> <img src="@/icon/3037139.png" style="width: 2em;"> Genel bilgiler ve iş listesi</h6>
        <template v-if="!p_showDetails.mode || p_showDetails.mode === 'sentence'">
          <template v-for="(work, work_index) in d_workSubjectList.subjects[subject_name].work_list">
            <b-row>
              <b-col cols="1" style="text-align: right;">
                <img src="@/icon/1661274.png" style="width: 1.5em;">
              </b-col>
              <b-col cols="11">
                {{ work.label }}
              </b-col>
            </b-row>
            <b-row v-if="work.code">
              <b-col cols="1" style="text-align: right;">
              </b-col>
              <b-col cols="11">
                <h6 class="code-text"> {{ work.code }} </h6>
              </b-col>
            </b-row>
            <b-row v-if="work.link">
              <b-col cols="1" style="text-align: right;">
              </b-col>
              <b-col cols="11">
                <h6>
                  <a :href="work.link" target="_blank">{{ work.link}}</a> 
                </h6>
              </b-col>
            </b-row>
          </template>
        </template>
        <template v-else-if="p_showDetails.mode === 'table'">
          <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
            <thead>
              <tr>
                <th>Başlık</th>
                <th>Durumu</th>
                <th>Buton adı</th>
                <th>Tetikleme noktası</th>
                <th>Metod adı</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(work, work_index) in d_workSubjectList.subjects[subject_name].work_list">
                <tr>
                  <td> {{ work.label }} </td>
                  <td> {{ work.status }} </td>
                  <td> {{ work.button_name }} </td>
                  <td> {{ work.trigger_point }} </td>
                  <td> {{ work.method_name }} </td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <!-- <div class="code-text"> {{ f_createCreatesubjectsql(subject_name) }} </div> -->
        <!-- <b-textarea class="code-text" v-model="" rows="5"></b-textarea> -->
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'WorkSubject',
  components: {},
  props: {
    p_showDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_workSubjectList: require('@/options/subject_list').options
    }
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

