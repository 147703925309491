var data = {
  'subject_order': [
    'bsapage',
    'diagnosis',
    'drugapproval',
    'gfrpage',
    'treatmentcycleincreasepage',
    'treatmentdatechangepage',
    'treatmentdaymanagement',
    'treatmentdrugarrangepage',
    'treatmentdrugcycledayarrangement',
    'treatmentlist',
    'treatmentschemadrugs',
    'treatmentschemaselection',
    'unittreatmentlist',
    'version_general_v2_0_1',
    'codedeveloperinfo',
    'install',
    'license_info',
  ],
  'subjects': {
    'license_info': {
      'label': 'Lisans Bilgileri',
      'work_list': [
        { 'label': 'Kullanılan merkez bazlı (örneğin her bir hastane için bir adet) lisanslama yapılmaktadır. Her woncology uygulamasının aktif olduğu merkezde bir adet lisans olması gerekmektdir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': '' },
        { 'label': 'Ürün kod şablonu özelliği taşımaktadır. Kod şablonları HBYS sistemlerinde gerekli yönergelerle geliştirildikten sonra kullanılabilmektedir', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': '' },
      ]
    },
    'install': {
      'label': 'Kurulum',
      'work_list': [
        { 'label': 'Woncology klasöründeki README.md dosyası kurulumda yol göstermektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': '' },
        { 'label': 'NPM (Node Package Modules) uygulaması cihazda yok ise kurulumu yapılır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': '' },
        { 'label': 'Kurulum yapmak için aşağıdaki linke tıklayınız.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': 'https://nodejs.org/en/download/' },
        { 'label': 'Woncology ürünü kurulacak cihaza yüklenir. Web tabanlı bir uygulama olduğu için Windows, Linux, MacOS işletim sistemlerinde çalıştırılabilmektedir. Ürün satın alma işlemi için iletişime geçmek için lütfen aşağıdaki linke tıklayınız.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '', 'link': 'https://www.wisdomera.io/#/web/contact' },
        { 'label': 'Terminal açılarak woncology klasörünün içerisine girilir. Aşağıdaki örnekte ürün c:\ konumu altına eklenmiştir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'c:\\woncology', 'link': '' },
        { 'label': 'Woncology ürün kurulum işlemi için aşağıdaki komutu yazıp enter yapılarak çalıştırılır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'npm install', 'link': '' },
        { 'label': 'Uygulamayı localhost:8080 linkinde developer modunda çalıştırmak için terminal açılarak woncology klasörünün içerisine girilir ve aşağıdaki kod çalıştırılır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'npm run serve', 'link': '' },
        { 'label': 'Uygulama çalıştırıldığında web browser (chrome, firefox v.b) açılarak localhost:8080 yazılarak uygulama çalıştırılır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'localhost:8080', 'link': '' },
      ]
    },
    'codedeveloperinfo': {
      'label': 'Kod geliştirici bilgileri',
      'work_list': [
        { 'label': 'Örneklerle komponent kullanımları oluşturulmuştur.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '<OrnekComponentAdi></OrnekComponentAdi>' },
        { 'label': 'Sayfalarda gezinirken, web konsolunu f12 ile açarak tetiklemelerin console.log larını izleyebilirsiniz.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Vuex store sayesinde tüm sayfalardan aynı bilginin değiştirilmesi (mutasyonu) ve kolayca erişim imkanı sağlanmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Kodlar oluşturulurken değişkenler için taglar anlaşılır olarak dizayn edilmiştir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Kodlar oluşturulurken data içerisindeki değişkenler datanın d sini ifade eder şekilde d_ prefix değeri ile başlatılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'd_exampleVariable' },
        { 'label': 'Kodlar oluşturulurken methods içerisindeki değişkenler fonksiyonun f sini ifade eder şekilde f_ prefix değeri ile başlatılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'f_exampleMethod' },
        { 'label': 'Kodlar oluşturulurken props içerisindeki değişkenler propsun p sini ifade eder şekilde p_ prefix değeri ile başlatılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'p_examplePropsVariable' },
        { 'label': 'Kodlar oluşturulurken getters içerisindeki değişkenler Store prefix değeri ile başlatılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'StoreExampleData' },
        { 'label': 'Kodlar oluşturulurken vuex mutasyon tipleri tanımlanırken değişkenler Mutation prefix değeri ile başlatılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': 'MutationExampleData' },
        { 'label': 'Fonksiyon tetiklemelerinde console.log kullanılarak geliştiricinin hızlıca fonksiyona odaklanmasını sağlanmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Hazırlanan vue komponentleri geliştirilen uygulamada istenilen yere props larını tanımlayarak ekleyenebilecek şekilde dizayn edilmiştir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Geliştiricilerin direkt komponentlere odaklanmalarını kolaylaştırmak için sayfalarda minimum kod görünümü dizaynı yapılmıştır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Sql sonrası frontendde kullanılacak JSON verilerine ait örnekler example_data klasörü içerisine eklenmiştir. Böylece sql sonrası verinin hazırlanması işlemi sonrası beklenen veri modeli yapısı da burada görüntülenebilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
        { 'label': 'Sql işlemi sonrası verinin frontendde kullanılması ile ilgili modifiye edilmesi fonksiyonları tanımlanmıştır. Böylece sql sonrası verinin kullanıldığı alanlarda istendiği formata dönüştürülmesi işlemleri de sağlanmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '', 'code': '' },
      ]
    },
    'version_general_v2_0_1': {
      'label': 'Versiyon genel özellikleri',
      'work_list': [
        { 'label': 'Tanı ekleyebilme', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Vücut yüzey alanı (VYA) hesaplama ve ilaçlar için hesaplamada kullanım fonksiyonları', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Glomerüler filtrasyon hızı (GFR) hesaplama ve ilaçlar için hesaplamada kullanım fonksiyonları', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'İlaç onay state yönetimi', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Kullanıcılar arasında ortak ilaç yönetim etkileşimi oluşturma', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'İlaç tedavi uygulama periyodik, siklus bazlı ve tarih yönetimi', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Vaka tedavi listesi görüntüleme komponentleri', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Bugüne ait tüm vaka tedavilerinin toplu olarak görüntülenmesi ve yönetimi', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Geliştirmeleri hazır iş paketleriyle kolayca takip edebilme', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Hazır tablo dizaynları', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Hazır fonksiyonel dizaynlar', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '31 adet tablo, oluşturma sqlleri ve verileri', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '18 adet hazır vue komponenti', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '12 adet hazır vuex store mutasyon fonksiyonu ', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'bsapage': {
      'label': 'Vücut yüzey alanı (VYA)',
      'work_list': [
        { 'label': 'Onkoloji tedavilerinde ilaçların dozlarında hesaplamalarda vücut yüzey alanı değeri kullanılmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Vücut yüzey alanı değerinin en güncel olduğu değer kullanılarak ilaç dozlarının hesabı yapılması gerekmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Hastanın kilo değişimiyle birlikte ilaç dozları da değişmektedir. Bu nedenle vücut yüzey alanı hesaplamalarının güncel olması çok önemlidir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },

      ]
    },
    'diagnosis': {
      'label': 'Tanı seçimi',
      'work_list': [
        { 'label': 'Yeni tedavi oluşturma ekranı açılırken seçili hastanın c kodu (kanser tanısı) olan tanı listesi de getirilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Tanı listesinden ilgili seçimi kullanıcı yapar.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Zorunlu alan olarak dizayn edilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'drugapproval': {
      'label': 'İlaç onay',
      'work_list': [
        { 'label': 'Adımlar sırayla ilerletilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Bunun için stateler arasındaki ilişki ve adım sıralaması kontrol edilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Burada 32 buton işlemi mevcuttur.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'gfrpage': {
      'label': 'Glomerüler filtrasyon hızı (GFR)',
      'work_list': [
        { 'label': 'İlaçların dozlarında hesaplamalarda glomerüler filtrasyon hızı (GFR) değeri kullanılmaktadır. Örneğin auc formülünde ilgili değer kullanılarak tedavi dozları belirlenen ilaçlar bulunmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Güncel kreatinin değeri ve kilo değişimleriyle değişen bir bilgi olduğundan güncel olması gerekmektedir. Örneğin kreatinin değeri normalin üzerine çıkan bir hastada ilaç dozları değişecektir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Hastada toksik etkilerin oluşmaması için güncel bir GFR hesaplama değerinin kullanılması konusunda kullanıcı tedavi onayı yapacağında uyarılmalıdır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentcycleincreasepage': {
      'label': 'Siklus artırma',
      'work_list': [
        { 'label': 'Seçili ilaçlara, belirlenen peryod ve günlerde son siklustaki dozları dikkate alınarak belirlediğiniz siklus sayısınca ekleme yapılmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Son tedavi siklusundaki ilaçlar listesi, period ve gün bilgileri yeni period ekleme sayfasında açılır', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Burada period ve günler düzenlenebilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentdatechangepage': {
      'label': 'Tedavi tarih öteleme',
      'work_list': [
        { 'label': 'Hastaya planlanan tedavinin tarihleri kullanıcı tarafından ötenebilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Bu bazen bir ilaç için ya da toplu olarak o gündeki tüm ilaçlar için yapılabilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Genellikle toplu olarak yapıldığından öncelikle toplu olarak tarih öteleme açılır penceresi detaylı tarih düzenleme ekranından ayrıca dizayn edilmelidir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentdaymanagement': {
      'label': 'Tedavi gün yönetimi',
      'work_list': [
        { 'label': 'Hastaya planlanan tedavinin hangi günlerde uygulanacağı bu ekranda görüntülenir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Yine hastanın geçmiş, bugün ve gelecek tedavilerinin görüntülenmesi sağlanır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Hasta bazlı olarak seçilen tedavisine ait ilaçlarının yönetimini doktor, hemşire, eczane yönetimi tek ekran olarak buradan yönetilmesi sağlanır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentdrugarrangepage': {
      'label': 'Tedavi ilaç uygulama düzenleme',
      'work_list': [
        { 'label': 'Hastaya planlanan tedavideki ilaç dozları kullanıcı tarafından düzenlenebilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Bu bazen bir ilaç için ya da toplu olarak o gündeki tüm ilaçlar için ya da sonraki günler için de yapılabilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Genellikle toplu olarak yapılmaktadır. “Yaptığım deği̇şi̇kli̇kleri̇ bugünden sonraki̇ tüm günlere de uygula” şeklinde seçili olarak default olarak ekran açılmaktadır. Böylece kullanıcı isterse sonraki günlerde doz değişimi yapmak istemiyorsa bu checki kaldırmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentdrugcycledayarrangement': {
      'label': 'Tedavi gün siklus düzenleme',
      'work_list': [
        { 'label': 'Tedavideki ilaçlara ait henüz onaylanmamış tedavilere ait günler, ilaç bazlı olarak her bir siklustaki günler ayrı ayrı olarak düzenlenebilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Seçili tedaviye ait ilaçlar ilaç bazlı olarak günleri ve tarihleri gösterilecek şekilde PatientTreatmentDrugsApplication tablosundan listelenir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Kullanıcı günleri silebilir, gün değerini değiştirerek ilaca ait uygulama tarihini değiştirebilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentlist': {
      'label': 'Hasta tedavi listesi',
      'work_list': [
        { 'label': 'Hastaların daha önce aldığı tedavi listesinin görüntülenmesi onkoloji hastalarının en önemli konularından birisidir. Bu nedenle önceki uygulanan tedavilerin ve aktif tedavilerin listelenmesi hastanın toksik durumlarının daha kolay anlaşılmasını sağlar.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Güncel kreatinin değeri ve kilo değişimleriyle değişen bir bilgi olduğundan güncel olması gerekmektedir. Örneğin kreatinin değeri normalin üzerine çıkan bir hastada ilaç dozları değişecektir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Bu nedenle hastada toksik etkilerin oluşmaması için güncel bir GFR hesaplama değerinin kullanılması konusunda kullanıcı tedavi onayı yapacağında uyarılmalıdır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentschemadrugs': {
      'label': 'Tedavi şemaları i̇laçları',
      'work_list': [
        { 'label': 'Şemalar ilaçlar ve ilaçlara ait doz bilgileri uygulama şekilleri gibi bilgileri barındırmaktadır. Bu bilgiler de tedavinin uygulama sürecinde kulanılmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Tedavi şemasına ait ilaçlar tedavi seçildiğinde tedavi şema id ile seçilerek ekrana getirilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Tedavi şemaları sistemdeki genel kayıtlı şemalardan olabileceği gibi, kullanıcının kendisine özel olarak kaydettiği tablodan da getirilebilir. Kullanıcı şema tipini seçtiğinde bu tablo belirlenir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'treatmentschemaselection': {
      'label': 'Tedavi şemaları',
      'work_list': [
        { 'label': 'Onkoloji tedavi yönetiminde yeni tedaviler kemoterapi, immünoterapi, oral hedefe yönelik tedaviler ve hormonoterapi olarak gruplandırılabilir. Genel olarak periodik olarak uygulanır. Dolayısıyla periodik olarak yönetilebilmesi çok önemlidir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Dünyadaki literatürler ve klavuzlar da baz alındığında kabul görmüş dozları, uygulama şekilleri belirlenmiş tedaviler bulunmaktadır. Hazır şablonlar üzerinden tedavi başlatılması ilgili uzmanın sürecini kolaylaştırmaktadır. Ayrıca daha güvenli olmasını sağlamaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Yeni tedavi oluşturma ekranı mümkün olduğunca input alanları minimum sayıda yeni açılır ekranla dizayn edilmelidir. Çünkü yeni tedavi oluşturma ekranında bir çok seçim yapılmaktadır. Kompakt bir yapı kullanıcının işlemlerini yapmasını kolaylaştırmaktadır. Böylece daha kullanıcı dostu bir yapı oluşmaktadır.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
    'unittreatmentlist': {
      'label': 'Ünite tedavi listesi',
      'work_list': [
        { 'label': 'Hasta bazlı tedavi yönetimleri yapılabildiği gibi, bugündeki tüm hastalara ait tedavilerin liste halinde görülebilir ve ve onay süreçlerinin yapılabilir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Böylece gün içerisindeki tüm hastalara ait tedaviler toplu olarak yapılabilmektedir.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': 'Tüm hastaları kemoterapi ünitesindeki yetkili olan kullanıcılar görüntüleyebilir ve işlemleri hızlıca burada gerçekleştirebilirler.', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
        { 'label': '...', 'status': 0, 'button_name': '', 'trigger_point': '', 'method_name': '' },
      ]
    },
  }
};

export { data as options };
